// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  height: 100%;
}

.logo-container {
  background-color: #013161;
  height: 150%;
  border-radius: 26px;
  transform: rotate(298deg) translate(26%, -60%);
}

.logo-container::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #184b94;
  border-radius: 26px;
  transform: translate(-30%, 110%);
}

.logo-container::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(25, 75, 148, 0.5803921569);
  border-radius: 26px;
  transform: translate(-20%, 65%);
}

.title {
  font-size: 2.75rem;
  font-weight: 600;
}

.container {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container {
  margin-bottom: 30px;
}

.btn_eye_icon {
  position: absolute;
  right: 0;
  bottom: 4px;
  background: transparent;
  z-index: 2;
}
.btn_eye_icon ion-icon {
  font-size: 22px;
  color: #757575;
}

.btn_eye_icon:focus {
  outline: none !important;
}

ion-button {
  margin: 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.page.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,8CAAA;AAEF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gCAAA;AACF;;AACA;EACE,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,2CAAA;EACA,mBAAA;EACA,+BAAA;AAEF;;AACA;EACE,kBAAA;EACA,gBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAGF;;AACA;EACE,mBAAA;AAEF;;AACA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,uBAAA;EACA,UAAA;AAEF;AADE;EACI,eAAA;EACA,cAAA;AAGN;;AACA;EACE,wBAAA;AAEF;;AACA;EACE,cAAA;AAEF","sourcesContent":["ion-content{\n  height: 100%;\n}\n.logo-container {\n  background-color: #013161;\n  height: 150%;\n  border-radius: 26px;\n  transform: rotate(\n298deg) translate(26%, -60%);\n}\n\n.logo-container::after{\n  content: \"\";\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  background: #184b94;\n  border-radius: 26px;\n  transform: translate(-30%, 110%);\n}\n.logo-container::before{\n  content: \"\";\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  background: #194b9494;\n  border-radius: 26px;\n  transform: translate(-20%, 65%);\n}\n\n.title {\n  font-size: 2.75rem;\n  font-weight: 600;\n}\n.container{\n  text-align: center;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n}\n\n.form-container{\n  margin-bottom: 30px;\n}\n\n.btn_eye_icon{\n  position: absolute;\n  right: 0;\n  bottom: 4px;\n  background: transparent;\n  z-index: 2;\n  ion-icon{\n      font-size: 22px;\n      color:#757575;\n  }\n}\n\n.btn_eye_icon:focus{\n  outline: none  !important;\n}\n\nion-button{\n  margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
